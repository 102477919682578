import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function App() {
  const { path } = useParams();

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`https://hifive-server.herokuapp.com/get-redirect-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ path: window.location.pathname, origin: "scanclicktip" }),
      });

      const data = await response.json();
      window.location.replace(data.data.redirect_url);
    }
    window.location.pathname !== '/' ? fetchData() : console.log('no path');
  })

  return (
    <div className="App">
      <h1>Loading...</h1>
    </div>
  );
}

export default App;
